import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

let initialState = {
  loginInfo: {},
  isLoggedin: false,
  loginAdminInfo: {},
  isAdminLoggedin: false,
  currency: '',
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateLoginInfo: (state, action) => {
      state.loginInfo = action.payload;
      state.isLoggedin = true;
    },
    removeLoginInfo(state) {
      state.loginInfo = '';
      state.isLoggedin = false;
    },
    updateAdminLoginInfo: (state, action) => {
      state.loginAdminInfo = action.payload;
      state.isAdminLoggedin = true;
    },
    removeAdminLoginInfo(state) {
      state.loginAdminInfo = '';
      state.isAdminLoggedin = false;
    },
    updateCurrency(state, action) {
      state.currency = action.payload;
    },
  },
});

export function useAuthStore() {
  const loginInfo = useSelector((state) => state.auth.loginInfo);
  const isLoggedin = useSelector((state) => state.auth.isLoggedin);
  const loginAdminInfo = useSelector((state) => state.auth.loginAdminInfo);
  const isAdminLoggedin = useSelector((state) => state.auth.isAdminLoggedin);
  const currency = useSelector((state) => state.auth.currency);
  const dispatch = useDispatch();
  return {
    loginInfo,
    isLoggedin,
    loginAdminInfo,
    isAdminLoggedin,
    updateLoginInfo: (payload) => dispatch(updateLoginInfo(payload)),
    removeLoginInfo: () => dispatch(removeLoginInfo()),
    updateAdminLoginInfo: (payload) => dispatch(updateAdminLoginInfo(payload)),
    removeAdminLoginInfo: () => dispatch(removeAdminLoginInfo()),
    currency,
    updateCurrency: (payload) => dispatch(updateCurrency(payload)),
  };
}

export const {
  updateLoginInfo,
  removeLoginInfo,
  updateAdminLoginInfo,
  removeAdminLoginInfo,
  updateCurrency,
} = authSlice.actions;

export default authSlice.reducer;
