import React from 'react';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import authSlice from './features/authSlice';
import notifySlice from './features/notifySlice';
import userRedirectSlice from './features/userRedirectSlice';
import renderStateWithdrawalSlice from './features/renderStateWithdrawalSlice';
import revenueGraphSlice from './features/revenueGraphSlice';
import dashboardSlice from './features/dashboardSlice';

const appReducers = combineReducers({
  auth: authSlice,
  notify: notifySlice,
  redirect: userRedirectSlice,
  withdrwalRequestRender: renderStateWithdrawalSlice,
  revenueData: revenueGraphSlice,
  dashboard: dashboardSlice,
});

const rootReducer = (state, action) => {
  return appReducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_REDUX_HYDRATE_KEY || 'ABCD123456789',
      onError: function (error) {
        // DOCS: Clearing the state on encryption error
        localStorage.clear();
        window.location.reload();
      },
    }),
  ],
  // blacklist: ["postPanJourney", "prePanJourney"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);

export function StoreProvider({ children }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
