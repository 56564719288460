import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../redux/features/authSlice';

export const PrivateRoute = ({ children }) => {
  let { isLoggedin } = useAuthStore();
  let auth = isLoggedin;
  return auth ? children : <Navigate to="/" />;
};

export const PublicRoute = ({ children }) => {
  let { isLoggedin } = useAuthStore();
  let auth = !isLoggedin;
  return auth ? children : <Navigate to="/" />;
};

export const ForRenterRoute = ({ children }) => {
  let { loginInfo } = useAuthStore();
  let forRenter = loginInfo.role === 'renter';
  return forRenter ? <Navigate to="/dashboard" /> : children;
};

export const ForLandlordRoute = ({ children }) => {
  let { loginInfo } = useAuthStore();
  let forLandlord =
    loginInfo.role === 'landlord' ||
    loginInfo.role === 'realtor' ||
    loginInfo.role === 'propertyManager';
  return forLandlord ? <Navigate to="/dashboard" /> : children;
};

// export const ForAdminRoute = ({ children }) => {
//   let { loginAdminInfo } = useAuthStore();
//   let forAdmin = loginAdminInfo.role === 'superadmin';
//   return forAdmin ? <Navigate to="/admin/dashboard" /> : children;
// };

export const PrivateRouteAdmin = ({ children }) => {
  let { isAdminLoggedin } = useAuthStore();
  let auth = isAdminLoggedin;
  return auth ? children : <Navigate to="/admin" />;
};

export const PublicRouteAdmin = ({ children }) => {
  let { isAdminLoggedin } = useAuthStore();
  let auth = isAdminLoggedin;
  return auth ? <Navigate to="/admin/dashboard" /> : children;
};

export const lazyRoute = (importFn, componentName) =>
  React.lazy(() =>
    importFn().then((module) => ({
      default: module[componentName],
    }))
  );
