import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export function useDashboardStore() {
  const isUserDashboardSideMenuOpen = useSelector(
    (state) => state.dashboard?.isUserDashboardSideMenuOpen
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserDashboardSideMenuOpen === undefined) {
      dispatch(closeUserDashboardSideMenu());
    }
  }, [isUserDashboardSideMenuOpen]);

  return {
    isUserDashboardSideMenuOpen,
    toggleUserDashboardSideMenu: () => dispatch(toggleUserDashboardSideMenu()),
    closeUserDashboardSideMenu: () => dispatch(closeUserDashboardSideMenu()),
  };
}

const initialState = {
  isUserDashboardSideMenuOpen: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard-slice-content',
  initialState,
  reducers: {
    toggleUserDashboardSideMenu: (state) => {
      state.isUserDashboardSideMenuOpen =
        !state?.isUserDashboardSideMenuOpen || true;
    },
    closeUserDashboardSideMenu: (state) => {
      state.isUserDashboardSideMenuOpen = false;
    },
  },
});

export const { toggleUserDashboardSideMenu, closeUserDashboardSideMenu } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
